<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Planillas Redireccionamiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">
                    Planillas Redireccionamiento
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <!-- Botones Iniciales -->
                    <div class="btn-group shadow float-right">
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        style="cursor: pointer"
                        @click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.planillaRedireccionamiento.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm shadow-sm"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">
                        <label>Empresa</label>
                        <v-select
                          class="form-control form-control-sm p-0"
                          v-model="empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          :options="listasForms.empresas"
                          @input="selectEmpresa()"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                        ></v-select>
                      </th>
                      <th class="text-center">
                        <label>Fecha Inicial</label>
                        <input
                          type="date"
                          v-model="filtros.fecha_ini"
                          label="id"
                          class="form-control form-control-sm shadow-sm"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        <label>Fecha Final</label>
                        <input
                          type="date"
                          v-model="filtros.fecha_fin"
                          label="id"
                          class="form-control form-control-sm shadow-sm"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center p-3">
                        <label>Cant Firmantes</label>
                      </th>
                      <th class="text-center p-3"><label>Firmados</label></th>
                      <th class="text-center p-3"><label>Acciones</label></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="planilla in planillas.data" :key="planilla.id">
                      <td>
                        <a
                          :href="uri_docs + planilla.empresa.logo"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + planilla.empresa.logo"
                            alt="Logo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td class="text-center">
                        {{ planilla.empresa.razon_social }}
                      </td>
                      <td class="text-center">
                        {{ planilla.fecha_inicial }}
                      </td>
                      <td class="text-center">
                        {{ planilla.fecha_final }}
                      </td>
                      <td class="text-center">
                        <span
                          :class="
                            planilla.estado == 1
                              ? 'badge bg-warning'
                              : planilla.estado == 2
                              ? 'badge bg-success'
                              : planilla.estado == 3
                              ? 'badge bg-purple'
                              : ''
                          "
                        >
                          {{ planilla.nEstado }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            planilla.planilla_firmas.length == 0
                              ? 'bg-danger'
                              : planilla.planilla_firmas.length ==
                                listasForms.firmantes.length
                              ? 'bg-success'
                              : 'bg-warning'
                          "
                        >
                          {{ planilla.planilla_firmas.length }} /
                          {{ listasForms.firmantes.length }}</span
                        >
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          planilla.planilla_firmas &&
                            planilla.planilla_firmas.length > 0
                        "
                      >
                        <div
                          v-for="(firma, k) in planilla.planilla_firmas"
                          :key="k"
                        >
                          <span class="badge bg-navy text-nowrap">
                            {{ k + 1 }}.{{ firma.user.name }}</span
                          >
                        </div>
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-warning text-nowrap">
                          Sin Firmas</span
                        >
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <!-- Botón Imprimir -->
                          <button
                            type="button"
                            class="btn btn-sm mr-1"
                            :class="
                              listasForms.firmantes.length ==
                              planilla.planilla_firmas.length
                                ? 'border border-primary alert-default-primary'
                                : 'border border-dark alert-default-dark'
                            "
                            title="Ver PDF"
                            @click="verPDF(planilla.id)"
                            v-if="
                              [2, 3].includes(planilla.estado) &&
                                $store.getters.can(
                                  'hidrocarburos.planillaRedireccionamiento.print'
                                )
                            "
                          >
                            <i class="fas fa-print"></i>
                          </button>
                          <!-- Botón Firmas -->
                          <button
                            type="button"
                            class="btn btn-sm mr-1"
                            :class="
                              listasForms.firmantes.length ==
                              planilla.planilla_firmas.length
                                ? 'border border-success alert-default-success'
                                : 'border border-dark alert-default-dark'
                            "
                            data-toggle="modal"
                            data-target="#Modal_firmas"
                            title="Firmar Planilla"
                            @click="
                              $refs.planillaRedireccionamientoFirmas.getDataFirmas(
                                planilla
                              )
                            "
                            v-if="
                              planilla.adjuntos &&
                                planilla.adjuntos.length > 0 &&
                                [2, 3].includes(planilla.estado) &&
                                $store.getters.can(
                                  'hidrocarburos.planillaRedireccionamiento.firmar'
                                )
                            "
                          >
                            <i class="fas fa-signature"></i>
                          </button>
                          <!-- Botón Archivo -->
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-soportes"
                            class="btn btn-sm mr-1"
                            :class="
                              planilla.adjuntos && planilla.adjuntos.length > 0
                                ? 'border border-info alert-default-info'
                                : 'border border-warning alert-default-warning'
                            "
                            title="Soportes"
                            @click="llenarModal(planilla.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.planillaRedireccionamiento.cargueSoportes'
                              ) && planilla.estado != 2
                            "
                          >
                            <i class="fas fa-upload"></i>
                          </button>
                          <!-- Botón Editar -->
                          <button
                            type="button"
                            class="btn btn-sm border border-dark bagde bg-navy"
                            title="Editar Planilla"
                            @click="edit(planilla)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.planillaRedireccionamiento.edit'
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="planillas.total">
                  <p>
                    Mostrando del <b>{{ planillas.from }}</b> al
                    <b>{{ planillas.to }}</b> de un total:
                    <b>{{ planillas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right shadow"
                  :data="planillas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <!-- Modal Adjuntos soportes-->
        <div
          class="modal fade"
          id="modal-soportes"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">Cargue Soportes</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div
                  class="card-body rounded alert-default-info border border-primary"
                >
                  <div class="row justify-content-center">
                    <div class="col-md-12">
                      <label for="files">Subir Soporte</label>
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-11">
                        <input
                          type="file"
                          multiple
                          id="files"
                          ref="files"
                          class="form-control-file btn btn-sm border border-success alert-success"
                          accept=".jpg, .JPG, .png, .PNG"
                          style="min-width: 320px;"
                          :class="
                            files
                              ? 'btn btn-sm border border-success alert-default-success'
                              : 'btn btn-sm border border-warning alert-default-warning'
                          "
                          @change="elegirDirectorio()"
                        />
                      </div>
                      <div class="col-md-1">
                        <button
                          v-if="!loading"
                          id="guardar_documento"
                          type="button"
                          class="btn btn-sm btn-success image-hover"
                          data-html="true"
                          title="Guardar Documento"
                          data-toggle="tooltip"
                          @click="saveFiles()"
                        >
                          <i class="fas fa-upload"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="progress mt-3" v-if="loading">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      :class="
                        progress < 50
                          ? 'bg-danger'
                          : progress > 50 && progress < 98
                          ? 'bg-warning'
                          : 'bg-success'
                      "
                      role="progressbar"
                      :style="'width:' + progress + '%'"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ progress }}%
                    </div>
                  </div>
                  <div
                    v-if="soportes.length > 0"
                    class="card-body rounded mt-2 bg-white"
                    :class="'border border-info'"
                    style="overflow-y: scroll"
                    :style="
                      soportes.length < 6
                        ? { height: '215px' }
                        : { height: '400px' }
                    "
                  >
                    <div class="row justify-content-center">
                      <div
                        class="card m-1 p-0 col-md-2 shadow"
                        v-for="soporte in soportes"
                        :key="soporte.id"
                      >
                        <div
                          class="card-header p-0 alert-default-secondary border border-dark"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.planillaRedireccionamiento.deleteSoporte'
                            )
                          "
                        >
                          <div class="btn-group float-right m-1">
                            <button
                              type="button"
                              class="btn btn-xs bg-gradient-danger image-hover"
                              @click="destroyFile(soporte.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="card-body p-1 rounded-bottom border border-primary"
                        >
                          <a :href="uri_docs + soporte.link" target="_blank">
                            <img
                              class="rounded image-hover"
                              :src="uri_docs + soporte.link"
                              type="image/jpeg"
                              width="108"
                              height="110"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PlanillaRedireccionamientoFirmas
      ref="planillaRedireccionamientoFirmas"
    ></PlanillaRedireccionamientoFirmas>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import PlanillaRedireccionamientoFirmas from "./PlanillaRedireccionamientoFirmas";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "PlanillaRedireccionamientoIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
    vSelect,
    PlanillaRedireccionamientoFirmas,
  },
  data() {
    return {
      cargando: false,
      files: null,
      progress: null,
      loading: false,
      planilla_id: null,
      soportes: [],
      filtros: {
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      listasForms: {
        empresas: [],
        usuarios: [],
        firmantes: [],
        estados: [],
      },
      empresa: {},
      planillas: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/planillasRedireccionamiento?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.planillas = response.data;
        });
      this.cargando = false;
    },

    async getEmpresas() {
      await axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/205").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getUsers() {
      await axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            frontera: true,
          },
        })
        .then((response) => {
          this.listasForms.usuarios = response.data;
        });
    },

    async getFirmantes() {
      await axios.get("/api/lista/206").then((response) => {
        this.listasForms.firmantes = response.data.sort((a, b) => {
          return a.numeracion - b.numeracion; // Ordena de menor a mayor
        });
      });
    },

    async selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
      await this.getIndex();
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/PlanillasRedireccionamientoForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(planilla) {
      return this.$router.push({
        name: "/Hidrocarburos/PlanillasRedireccionamientoForm",
        params: {
          accion: "Editar",
          data_edit: planilla,
          id: planilla.id,
        },
      });
    },

    verPDF(planilla) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/planillasRedireccionamiento/pdf/" + planilla,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    elegirDirectorio() {
      this.files = this.$refs.files.files;
    },

    saveFiles() {
      var vm = this;
      vm.progress = null;
      vm.loading = true;
      this.cargando = true;
      var total = 0;
      var count = 0;
      let formData = new FormData();
      if (vm.files) {
        for (var i = 0; i < vm.files.length; i++) {
          let file = vm.files[i];
          formData.append("link" + "-" + i, file);
          count = i + 1;
        }
        formData.append("planilla_id", this.planilla_id);
        formData.append("total", count);
        axios
          .post(
            "api/hidrocarburos/planillasRedireccionamiento/cargarArchivos",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                vm.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then((response) => {
            let icon = null;
            let title = null;
            let timer = null;
            vm.soportes = response.data;
            vm.files = null;
            vm.$refs.files.files = null;
            let inputFile = document.getElementById("files");
            inputFile.value = "";
            vm.progress == 100 ? (vm.loading = false) : (vm.loading = true);
            vm.$swal({
              icon: icon ? icon : "success",
              title: title ? title : "El archivo se cargó correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: timer ? timer : 3000,
              timerProgressBar: true,
            });
            vm.cargando = false;
          })
          .catch(function(error) {
            vm.loading = false;
            vm.cargando = false;
            vm.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        vm.loading = false;
        vm.cargando = false;
        vm.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    llenarModal(id) {
      this.cargando = true;
      this.planilla_id = id;
      this.files = null;
      this.$refs.files.files = null;
      let inputFile = document.getElementById("files");
      inputFile.value = "";
      axios
        .get("/api/hidrocarburos/planillasRedireccionamiento/getAdjuntos", {
          params: {
            planilla_id: this.planilla_id,
          },
        })
        .then((response) => {
          this.soportes = response.data;
          this.cargando = false;
        });
    },

    destroyFile(id) {
      this.$swal({
        title: "Esta seguro de eliminar este soporte?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete(
              "/api/hidrocarburos/planillasRedireccionamiento/deleteAdjunto/" +
                id
            )
            .then((response) => {
              this.llenarModal(this.planilla_id);
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        }
      });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getEmpresas();
    await this.getEstados();
    await this.getUsers();
    await this.getFirmantes();
  },
};
</script>
<style>
.image-hover {
  transition: transform 0.3s ease; /* Añade una transición suave */
}

.image-hover:hover {
  transform: scale(1.1); /* Aumenta el tamaño de la imagen al 110% */
}
</style>
